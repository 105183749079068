import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import CertifyImg1 from '@/assets/img/advocacy-for-children/school_certify1@2x.png';
import CertifyImg2 from '@/assets/img/advocacy-for-children/school_certify2@2x.png';
import CertifyImg3 from '@/assets/img/advocacy-for-children/school_certify3@2x.png';
import Obj1 from '@/assets/img/advocacy-for-children/school_obj1@2x.png';
import Obj2 from '@/assets/img/advocacy-for-children/school_obj2@2x.png';
import Obj3 from '@/assets/img/advocacy-for-children/school_obj3@2x.png';
import Obj4 from '@/assets/img/advocacy-for-children/school_obj4@2x.png';
import Obj5 from '@/assets/img/advocacy-for-children/school_obj5@2x.png';
import Obj6 from '@/assets/img/advocacy-for-children/school_obj6@2x.png';
import Obj7 from '@/assets/img/advocacy-for-children/school_obj7@2x.png';
import Obj8 from '@/assets/img/advocacy-for-children/school_obj8@2x.png';
import Obj9 from '@/assets/img/advocacy-for-children/school_obj9@2x.png';
import Processor1 from '@/assets/img/advocacy-for-children/school_processor1@2x.png';
import Processor2 from '@/assets/img/advocacy-for-children/school_processor2@2x.png';
import Processor3 from '@/assets/img/advocacy-for-children/school_processor3@2x.png';
import Processor4 from '@/assets/img/advocacy-for-children/school_processor4@2x.png';
import Processor5 from '@/assets/img/advocacy-for-children/school_processor5@2x.png';
import Processor6 from '@/assets/img/advocacy-for-children/school_processor6@2x.png';
import Arrow from '@/assets/img/icons/arrow_right_sky@2x.png';
import Quotes from '@/assets/img/icons/ico_quotes@2x.png';
import AccordionComponent, { AccordionItem } from '@/components/Accordion';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import { ObjList, CardFlex } from '@/components/Css/PublicPrivatePartnership';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import Section, { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PublicPrivateTab } from '@/page-blocks/advocacy-for-children/public-private-partnership';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const schoolData = [
  {
    id: 0,
    img: Obj1,
    no: `01`,
    tit: `아동권리 교육`,
  },
  {
    id: 1,
    img: Obj2,
    no: `02`,
    tit: `아동권리 거버넌스`,
  },
  {
    id: 2,
    img: Obj3,
    no: `03`,
    tit: `상호존중`,
  },
  {
    id: 3,
    img: Obj4,
    no: `04`,
    tit: `안전`,
  },
  {
    id: 4,
    img: Obj5,
    no: `05`,
    tit: `발달지원`,
  },
  {
    id: 5,
    img: Obj6,
    no: `06`,
    tit: `비차별`,
  },
  {
    id: 6,
    img: Obj7,
    no: `07`,
    tit: `교육가치 실현`,
  },
  {
    id: 7,
    img: Obj8,
    no: `08`,
    tit: `참여`,
  },
  {
    id: 8,
    img: Obj9,
    no: `09`,
    tit: `임파워링`,
  },
];
const accordionData = [
  {
    id: 0,
    label: `등록`,
    data: [
      {
        dec: `<strong>대상: </strong>전국 초등학교`,
      },
      {
        dec: `<strong>등록방법: </strong>유니세프 아동권리교육팀 문의 (02-724-8571 / <strong style=" color: #1cabe2;"><LinkSafe to="mailto:cfs@unicef.or.kr" className="Link-item">cfs@unicef.or.kr</LinkSafe></strong>)`,
      },
    ],
  },
  {
    id: 1,
    label: `조성`,
    data: [
      {
        dec: `학교는 아동친화학교 구성요소를 갖추기 위한 계획을 수립·이행·모니터링합니다.`,
        processor: [
          {
            img: Processor1,
            tit: `아동권리교육`,
          },
          {
            img: Processor2,
            tit: `운영단 구성`,
          },
          {
            img: Processor3,
            tit: `권리 실태 조사`,
          },
          {
            img: Processor4,
            tit: `조성 계획 수립`,
          },
          {
            img: Processor5,
            tit: `계획 이행`,
          },
          {
            img: Processor6,
            tit: `모니터링`,
          },
        ],
      },
      {
        dec: `아동친화학교 조성 과정 전반에 유니세프아동친화학교 자문단의 컨설팅이 진행됩니다.`,
      },
    ],
  },
  {
    id: 2,
    label: `심의`,
    data: [
      {
        dec: `각 학교는 아동친화학교 운영 결과(씨앗) 및 운영 계획(새싹)에 대한 자료를 제출합니다.`,
      },
      {
        dec: `유니세프는 학교를 방문해 학교 활동 및 진전 사항을 살펴보고, 학교에서 제출한 자료와 방문결과를 종합적으로 검토해 학교가 아동친화학교 인증 심의 기준을 충족했는지 확인합니다.`,
      },
    ],
  },
  {
    id: 3,
    label: `인증`,
    data: [
      {
        dec: `유니세프는 심의 결과에 따라 학교를 아동친화학교로 인증합니다.`,
      },
      {
        dec: `유니세프아동친화학교의 심의 기준에 따라 개별 참여학교 대상 인증을 실시합니다.`,
      },
    ],
  },
];

const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `아동친화사회팀`,
    tel: `02-724-8564~5`,
    email: `cfs@unicef.or.kr`,
  },
];

const NavigationButton = styled.div`
  padding: 24px;
  cursor: pointer;
  position: relative;
`;
const AccordionBody = styled.div`
  padding: 24px;
  font-size: 16px;
  color: #2d2926;
  ${breakpoint(`mobile`)} {
    font-size: 14px;
    padding: 16px 0;
  }
`;
const ProcessorList = styled.ul`
  width: 100%;
  margin: 0 auto;
  margin-top: 12px;
  margin-bottom: 12px;
  max-width: 916px;
  position: relative;
  align-items: stretch;

  &:before,
  &::after {
    content: '';
    display: block;
    width: 85%;
    height: 1px;
    border-top: 1px dashed #1cabe2;
    position: absolute;
    top: 37px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: -1;
  }
  &::after {
    display: none;
  }

  & > li {
    width: 16.66%;
    text-align: center;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 7px;
      height: 13px;
      background: url(${Arrow}) center no-repeat;
      background-size: cover;
      position: absolute;
      top: 31px;
      right: -3px;
    }

    &:last-child {
      &:before {
        display: none;
      }
    }
    ${Image} {
      max-width: 75px;
      display: inline-block;
    }
    .dec {
      margin-top: 12px;
      display: inline-block;
      color: #1cabe2;
      white-space: nowrap;
    }
  }
  ${breakpoint(`tablet`)} {
    margin: 16px -12px;
    margin-left: -32px;
    width: auto;

    &::before,
    &::after {
      width: 70%;
    }
    &::after {
      display: block;
      top: 184px;
    }

    & > li {
      width: 33.33%;
      text-align: center;
      position: relative;
      margin-bottom: 28px;

      &:nth-child(3) {
        &:before {
          display: none;
        }
      }
    }
  }
  ${breakpoint(`mobile`)} {
    &::after {
      top: 180px;
    }
  }
`;
const History = styled.div`
  dl {
    display: flex;
    padding-bottom: 64px;
    position: relative;

    &:last-child {
      padding-bottom: 0;
    }

    &::before {
      content: '';
      display: block;
      width: 1px;
      border-right: 1px dashed #1cabe2;
      position: absolute;
      left: 110px;
      top: 0;
      bottom: 0;
    }

    &:first-child {
      &::before {
        top: 10px;
      }
    }
    &:last-child {
      &::before {
        bottom: auto;
        height: 10px;
      }
    }

    dt {
      width: 110px;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 9px;
        height: 9px;
        border-radius: 100%;
        background: #1cabe2;
        position: absolute;
        right: -5px;
        top: 10px;
      }
    }
    dd {
      width: calc(100% - 110px);
      padding-left: 35px;

      .dec {
        margin-top: 8px;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    dl {
      &::before {
        left: 85px;
      }
      dt {
        width: 85px;
      }
      dd {
        width: calc(100% - 85px);
        padding-top: 40px;
      }
    }
  }
`;
const SectionSchool = styled(Section)`
  ${ObjList} {
    & > li {
      width: 33.33%;
    }
  }
  ${breakpoint(`tablet`)} {
  }
`;
const SectionCertify = styled(Section)`
  ${CardFlex} {
    align-items: stretch;
    & > li {
      width: 33.33%;

      .card {
        background-color: #fff;
        border-radius: 0 0 40px 0;
        padding: 0;

        ${Tit} {
          width: 90%;
          max-width: 269px;
          border-radius: 48px;
          left: 50%;
          transform: translate(-50%, 0);
          text-align: center;
        }
      }
    }
    .top {
      background-color: #fff;
      text-align: center;
      padding: 70px 0px 0px;
      /* height: 100%; */

      ${Image} {
        display: inline-block;
        max-width: 140px;
        margin-bottom: 26px;
      }
      .dec {
        display: block;
      }
    }
    .btt {
      text-align: left;
      background-color: #f8f8f8;
      margin-top: 40px;
      margin-bottom: 40px;
      padding: 32px 24px 44px;
      .blit-list {
        strong {
          font-weight: 400;
          color: #1cabe2;
        }
      }
      .dec {
        margin-top: 16px;
        color: #828385;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    ${CardFlex} {
      & > li {
        width: 100%;
        margin-bottom: 68px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;
const SectionAfter = styled(Section)`
  .card-flex {
    align-items: stretch;
    & > li {
      width: 33.33%;
      margin-bottom: 32px;
    }

    .card {
      height: 100%;
      padding: 24px;
      border-radius: 20px;
      border: solid 2px #1cabe2;
      .dec {
        strong {
          font-weight: 400;
          color: #1cabe2;
        }
      }
      dd {
        margin-top: 8px;
      }
    }
  }
  .tip {
    text-align: right;
    font-size: 14px;
    color: #828385;
    margin-top: -52px;
  }

  .quote-wrap {
    margin-top: 64px;
    background: #1cabe2;
    position: relative;
    margin-right: 30px;
    padding: 80px 20px;
    text-align: center;

    &:before {
      content: '';
      display: block;
      background: #b2def1;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 30px;
      top: 30px;
      z-index: -1;
    }

    ${Tit} {
      max-width: 754px;
      text-align: center;
      margin: 0 auto;
      display: inline-block;
      letter-spacing: -1.2px;

      &:before,
      &:after {
        content: '';
        display: block;
        width: 50px;
        height: 46px;
        background: url(${Quotes}) center no-repeat;
        background-size: cover;
        position: absolute;
      }
      &:before {
        left: 64px;
        top: 34px;
      }
      &:after {
        right: 54px;
        bottom: 53px;
        transform: rotate(180deg);
      }
    }
  }

  ${breakpoint(`tablet`)} {
    .card-flex {
      & > li {
        width: 50%;
        margin-bottom: 20px;
      }
    }
    .tip {
      margin-top: 0;
    }

    .quote-wrap {
      margin-top: 48px;
      margin-right: 0;
      padding: 77px 20px;
      text-align: center;

      &:before {
        left: 20px;
        top: 20px;
      }

      ${Tit} {
        &:before,
        &:after {
          width: 32px;
          height: 29px;
        }
        &:before {
          left: 24px;
          top: 24px;
        }
        &:after {
          right: 24px;
          bottom: 24px;
        }
      }
    }
  }
  ${breakpoint(`mobile`)} {
    .card-flex {
      & > li {
        width: 100%;
      }
    }
    .tip {
      font-size: 12px;
    }
  }
`;

const SectionAccordion = styled(Section)`
  ${breakpoint(`tablet`)} {
  }
`;

const SectionAfter1 = styled(Section)`
  ${breakpoint(`tablet`)} {
  }
`;

const Page = styled.div``;

const ChildFriendlySchools: React.FC<PageProps> = ({ location }) => {
  const [news, setNews] = useState<CampaignData[]>([]);

  const loadNews = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A026'],
      });
      const articles = data as any;
      setNews(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadNews();
  }, [loadNews]);

  return (
    <LayoutWithTitle
      // 강제로 breadcrumb 고정
      location={{
        ...location,
        pathname: `/what-we-do/advocacy-for-children/public-private-partnership/child-friendly-cities`,
      }}
      paddingBtt
      title="아동친화사회 만들기"
      description="for every child, schools"
    >
      <PublicPrivateTab currentPath={location.pathname} />
      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  학교 구성원 모두가 행복한 학교 만들기, <br />
                  유니세프아동친화학교
                </PageComponent>
              </Tit>
            </h2>
            <p className="header-dec">
              <PageComponent id="content1">
                유니세프아동친화학교는 유엔아동권리협약을 기반으로 학교를
                운영하여, 아동권리 실현과 더불어 학교 구성원 모두의 권리를
                존중하는 학교 문화 만들기 프로그램입니다. 학생을 비롯한 학교
                구성원 모두가 아동권리를 이해하고 아동권리를 최우선으로 고려하여
                학교를 운영합니다. 이를 통해 학교 구성원 모두의 관계 속에서
                서로의 권리를 존중하는 법을 배우고, 아동권리를 존중하는 학교
                환경과 문화를 만들 수 있습니다. 학교 운영과 문화, 교풍 등 학교
                운영 전반에 유엔아동권리협약의 가치와 이념을 담아, 아동의 권리
                실현과 함께 학교 구성원 모두의 권리가 존중되는 학교 문화를
                만들어 갑니다. 이를 통해 학교 구성원 모두의 관계 속에서 권리
                존중 방법을 연습하고 태도를 익히며, 아동권리가 존중되는 학교
                환경을 실현할 수 있습니다.
              </PageComponent>
            </p>
          </SectionHeader>
          <PageComponent id="youtube1">
            <YoutubeSingle videoId="frTFYLuTSRU" />
          </PageComponent>
        </Container>
      </Section>

      <SectionSchool className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                유니세프아동친화학교의 <br />
                9가지 구성요소
              </Tit>
            </h2>
          </SectionHeader>
          <ObjList className="flex align-start">
            {schoolData.map((row, idnex) => (
              <li key={row.id}>
                <div className="img-wrap">
                  <Image pcSrc={row.img} mobileSrc={row.img} />
                </div>
                <div className="dec-wrap">
                  <strong className="no">{row.no}</strong>
                  <Tit
                    size="s4"
                    dangerouslySetInnerHTML={{ __html: row.tit }}
                  />
                </div>
              </li>
            ))}
          </ObjList>
        </Container>
      </SectionSchool>

      <SectionCertify className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">어떤 단계로 인증되나요?</Tit>
            </h2>
            <p className="header-dec">
              유니세프아동친화학교는 총 3단계를 거쳐 조성됩니다. 참여 학교는
              9가지 구성요소를 갖추기 위한 활동을 계획하고 이행하면서,
              아동들에게 나타나는 긍정적 변화를 모니터링하며 아동친화학교를
              조성합니다.
            </p>
          </SectionHeader>
          <CardFlex className="flex">
            <li>
              <div className="card" style={{ backgroundColor: '#f8f8f8' }}>
                <Tit size="s4" color="white">
                  아동친화씨앗학교
                </Tit>
                <div className="top">
                  <Image pcSrc={CertifyImg1} mobileSrc={CertifyImg1} />
                  <strong className="dec">
                    3~6개월 소요 <br />
                    인증 후 바로 새싹 학교 등록
                  </strong>
                  <div className="btt">
                    <ul className="blit-list">
                      <li>
                        학교 차원에서 <strong>계획을 수립</strong>하고{` `}
                        <strong>조성 기반을 마련</strong>하는 단계입니다.
                      </li>
                    </ul>
                    <p className="dec">
                      아동친화학교 구성요소를 바탕으로 교내 권리 존중 현황을
                      점검하고 아동친화학교 운영 계획을 수립합니다. 학교 구성원
                      대상 아동권리교육을 실시하고 수립한 운영 계획을
                      학교교육과정계획에 포함합니다.
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="card" style={{ backgroundColor: '#f8f8f8' }}>
                <Tit size="s4" color="white">
                  아동친화새싹학교
                </Tit>
                <div className="top">
                  <Image pcSrc={CertifyImg2} mobileSrc={CertifyImg2} />
                  <strong className="dec">
                    1년~1년 6개월 소요 <br />
                    인증유효기간: 2년
                  </strong>
                  <div className="btt">
                    <ul className="blit-list">
                      <li>
                        학교교육계획에 따라 유니세프아동친화학교{` `}
                        <strong>구성요소에 해당하는 각 활동을 진행</strong>
                        하는 단계입니다.
                      </li>
                    </ul>
                    <p className="dec">
                      학교 구성원 모두가 아동권리를 이해하고 있으며
                      유엔아동권리협약의 활동 가치와 이념이 학교의 문화와 교풍,
                      정책에 명시적으로 포함됩니다.
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="card" style={{ backgroundColor: '#f8f8f8' }}>
                <Tit size="s4" color="white">
                  아동친화열매학교
                </Tit>
                <div className="top">
                  <Image pcSrc={CertifyImg3} mobileSrc={CertifyImg3} />
                  <strong className="dec">
                    2~3년 소요 <br />
                    인증유효기간: 2년
                  </strong>
                  <div className="btt">
                    <ul className="blit-list">
                      <li>
                        학생과 교사, 학무모 등 학교 구성원을 통해 아동친화학교
                        조성을 위한 학교 차원의 노력에 대한{` `}
                        <strong>영향력을 확인</strong>할 수 있는 단계입니다.
                      </li>
                    </ul>
                    <p className="dec">
                      보다 심화된 아동친화학교 조성 계획을 수립하고 실행하며,
                      유엔아동권리협약의 가치와 이념이 학교 문화와 교풍, 정책에
                      완전히 내재됩니다. 학생들이 아동친화학교 조성 과정에
                      주도적 역할을 수행합니다.
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </CardFlex>
        </Container>
      </SectionCertify>

      <SectionAfter className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">어떤 변화가 생기나요?</Tit>
            </h2>
            <p className="header-dec">
              유니세프아동친화학교를 조성한 학교의 학생들과 성인에 긍정적 변화가
              나타납니다.
            </p>
          </SectionHeader>
          <ul className="card-flex flex">
            <li>
              <dl className="card">
                <dt>
                  <Tit size="s4" color="sky">
                    권리인식 향상
                  </Tit>
                </dt>
                <dd>
                  <p className="dec">
                    학생 <strong>82%</strong>가 학교에서 아동권리를 배웁니다.
                  </p>
                </dd>
              </dl>
            </li>
            <li>
              <dl className="card">
                <dt>
                  <Tit size="s4" color="sky">
                    행복도 증가
                  </Tit>
                </dt>
                <dd>
                  <p className="dec">
                    학생 <strong>87%</strong>가 행복한 학교 생활을 합니다.
                  </p>
                </dd>
              </dl>
            </li>
            <li>
              <dl className="card">
                <dt>
                  <Tit size="s4" color="sky">
                    의견 & 참여 증가
                  </Tit>
                </dt>
                <dd>
                  <p className="dec">
                    학생 <strong>82%</strong>가 학교에서 자신의 의견이
                    존중된다고 느낍니다.
                  </p>
                </dd>
              </dl>
            </li>
            <li>
              <dl className="card">
                <dt>
                  <Tit size="s4" color="sky">
                    안전도 상승
                  </Tit>
                </dt>
                <dd>
                  <p className="dec">
                    학생 <strong>82%</strong>가 학교를 안전한 공간으로
                    인식합니다.
                  </p>
                </dd>
              </dl>
            </li>
            <li>
              <dl className="card">
                <dt>
                  <Tit size="s4" color="sky">
                    교사 근무 만족도 상승
                  </Tit>
                </dt>
                <dd>
                  <p className="dec">
                    교사 <strong>98%</strong>의 근무 만족도가 높아집니다.
                  </p>
                </dd>
              </dl>
            </li>
          </ul>
          <p className="tip">[출처] 유니세프영국위원회, 2018영향보고서</p>

          <div className="quote-wrap">
            <Tit size="s4" color="white">
              아동친화학교가 되는 여정은 학생과 교사 어느 한 쪽의 노력만으로
              계속될 수 없습니다. <br />
              학생과 교사, 그 외 학교에서 근무하는 모든 직원들, 학부모를 비롯한
              학생 보호자, 지역 사회 구성원 등 <br />
              아동을 둘러싼 모든 아동과 성인이 함께 노력하며 조성해야 합니다.
            </Tit>
          </div>
        </Container>
      </SectionAfter>

      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">어떻게 참여하나요?</Tit>
            </h2>
          </SectionHeader>
          <AccordionComponent initialKey={accordionData[0].id}>
            {accordionData.map((row) => (
              <AccordionItem
                key={row.id}
                toggleKey={row.id}
                renderToggle={(onClick) => (
                  <NavigationButton
                    onClick={onClick}
                    className="accordion-opener"
                  >
                    <Tit size="s4">{row.label}</Tit>
                  </NavigationButton>
                )}
              >
                <AccordionBody>
                  <ul className="blit-list black">
                    {row.data.map((row2, index2) => (
                      <li key={index2}>
                        <p
                          className="dec"
                          dangerouslySetInnerHTML={{ __html: row2.dec }}
                        />
                        {row2.processor && (
                          <ProcessorList className="flex">
                            {row2.processor.map((row3, index3) => (
                              <li key={index3}>
                                <figure>
                                  <Image
                                    pcSrc={row3.img}
                                    mobileSrc={row3.img}
                                  />
                                </figure>
                                <strong className="dec">{row3.tit}</strong>
                              </li>
                            ))}
                          </ProcessorList>
                        )}
                      </li>
                    ))}
                  </ul>
                </AccordionBody>
              </AccordionItem>
            ))}
          </AccordionComponent>
        </Container>
      </Section>

      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">현황</Tit>
            </h2>
          </SectionHeader>
          <History>
            <dl>
              <dt>
                <Tit size="s3" color="sky">
                  2019년
                </Tit>
              </dt>
              <dd>
                <Tit size="s4">12월</Tit>
                <p className="dec">유니세프 - 충청북도교육청 업무 협약</p>
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s3" color="sky">
                  2020년
                </Tit>
              </dt>
              <dd>
                <Tit size="s4">10월</Tit>
                <p className="dec">
                  충청북도 유니세프아동친화학교 참여 학교 4개교 선정
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s3" color="sky">
                  2021년
                </Tit>
              </dt>
              <dd>
                <Tit size="s4">3월</Tit>
                <p className="dec">
                  충청북도 유니세프아동친화학교 '아동친화씨앗학교' 인증
                </p>
              </dd>
            </dl>
            <dl>
              <dt />
              <dd>
                <Tit size="s4">12월</Tit>
                <p className="dec">유니세프-서울특별시교육청 업무 협약</p>
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s3" color="sky">
                  2022년
                </Tit>
              </dt>
              <dd>
                <Tit size="s4">3월</Tit>
                <p className="dec">
                  충청북도 유니세프아동친화학교 '아동친화새싹학교' 인증
                  <br />
                  서울특별시 유니세프아동친화학교 참여 학교 11개교 선정
                </p>
              </dd>
            </dl>
            <dl>
              <dt />
              <dd>
                <Tit size="s4">12월</Tit>
                <p className="dec">유니세프-경상남도교육청 업무 협약</p>
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s3" color="sky">
                  2023년
                </Tit>
              </dt>
              <dd>
                <Tit size="s4">1월</Tit>
                <p className="dec">유니세프-제주특별자치도교육청 업무 협약</p>
              </dd>
            </dl>
            <dl>
              <dt />
              <dd>
                <Tit size="s4">2월</Tit>
                <p className="dec">
                  충청북도 유니세프아동친화학교 '아동친화열매학교' 3개교 인증
                  <br />
                  서울특별시 유니세프아동친화학교 '아동친화새싹학교' 5개교 인증
                  <br />
                  경상남도 유니세프아동친화학교 참여 초∙중∙고 10개교 선정
                </p>
              </dd>
            </dl>
          </History>
        </Container>
      </Section>

      <SectionCertify className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">참여 학교</Tit>
            </h2>
          </SectionHeader>
          <CardFlex className="flex">
            <li>
              <div className="card">
                <Tit size="s4" color="white">
                  아동친화씨앗학교
                </Tit>
                <div className="top">
                  <strong className="dec" style={{ marginBottom: '40px' }}>
                    서울 양원숲초등학교, 서울 서교초등학교, <br />
                    서울 수색초등학교, 서울 영중초등학교, <br />
                    서울 상원초등학교, 서울 거원초등학교, <br />
                    서울 송파초등학교, 서울 위례솔초등학교, <br />
                    서울 천일초등학교, 서울 풍납초등학교, <br />
                    서울 양화초등학교, 서울 매헌초등학교, <br />
                    서울 봉은초등학교, 서울 조원초등학교, <br />
                    서울 신자초등학교, 서울 중마초등학교, <br />
                    서울 개운초등학교, 고성 대성초등학교, <br />
                    진주 반성초등학교, 통영 제석초등학교, <br />
                    함안 대산초등학교, 함안 아라초등학교, <br />
                    고성 소가야중학교, 남해 상주중학교, <br />
                    진주 선인국제중학교, 김해 율하고등학교, <br />
                    창녕 남지고등학교, 울산 강동초등학교, <br />
                    울산 다전초등학교, 울산 외솔초등학교, <br />
                    울산 장검중학교, 울산 혜인학교
                  </strong>
                </div>
              </div>
            </li>
            <li>
              <div className="card">
                <Tit size="s4" color="white">
                  아동친화새싹학교
                </Tit>
                <div className="top">
                  <strong className="dec" style={{ marginBottom: '40px' }}>
                    서울 고일초등학교
                    <br />
                    서울 반원초등학교
                    <br />
                    서울 상천초등학교
                    <br />
                    서울 성일초등학교
                    <br />
                    서울 흥인초등학교
                    <br />
                    증평 죽리초등학교
                  </strong>
                </div>
              </div>
            </li>
            <li>
              <div className="card">
                <Tit size="s4" color="white">
                  아동친화열매학교
                </Tit>
                <div className="top">
                  <strong className="dec" style={{ marginBottom: '40px' }}>
                    청주 강서초등학교
                    <br />
                    충주 달천초등학교
                    <br />
                    음성 소이초등학교
                  </strong>
                </div>
              </div>
            </li>
          </CardFlex>
        </Container>
      </SectionCertify>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A026" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {news.length > 0 && (
            <RelativeBoardCard
              newslist={news}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>

      <Section className="by-sub-main-layout">
        <Container>
          <ContactUs itemData={contactData} />
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};
export default ChildFriendlySchools;
